<template>
  <div>

    <b-alert variant="danger" show v-if="!appid || appid.length === 0">
      <h4 class="alert-heading">
        Facebook App ID is not set
      </h4>
      <div class="alert-body">
        <span>Please set the Facebook App ID in the</span>
        
        <b-link :to="{ name: 'pages-facebook-setting' }" class="mx-1">Marketing Settings</b-link>
      </div>
    </b-alert>
    <div class="facebook__login card p-3 text-center bg-info" v-if="appid && appid.length > 0">
      <div class="text">
        <h3 class="text-white">Facebook Login</h3>
        <p class="text-white">Login with Facebook to view all your posts</p>
        <div id="app" class="d-flex justify-content-center">
          <v-facebook-login :app-id="firstAppId" v-model="model" @sdk-init="handleSdkInit" @login="onLogin"
            @logout="logout"></v-facebook-login>
        </div>
      </div>
    </div>
    <content-with-sidebar class="blog-wrapper">
      <!--Spinner  --->
      <div class="d-flex justify-content-center mb-1">
        <b-spinner variant="success" label="Spinning" block v-if="loadingMarketing"
          style="width: 3rem; height: 3rem"></b-spinner>
      </div>


      <!-- blogs -->
      <b-row class="blog-list-wrapper" v-if="!loadingMarketing">
        <b-col v-for="blog in posts" :key="blog.id" md="4">
          <b-card tag="article" no-body>
         
            <b-img :src="blog.fullPicture ? blog.fullPicture : facebookImage" class="card-img-top fluid" height="300" width="100" />

      
            <b-card-body>
              <b-card-title>
                <b-link class="blog-title-truncate text-body-heading">
                  {{ blog.message }}
                </b-link>
              </b-card-title>
              <b-media no-body>
                <b-media-aside vertical-align="center" class="mr-50">

                </b-media-aside>
                <b-media-body>
                  <small class="text-muted mr-50">by</small>
                  <small>
                    <b-link class="text-body">{{ userId.name }}</b-link>
                  </small>
                  <span class="text-muted ml-75 mr-50">|</span>
                  <small class="text-muted">{{ blog.updatedTime }}</small>
                </b-media-body>
              </b-media>

              <hr />
              <div class="d-flex justify-content-between align-items-center">
                <b-button class="font-weight-bold" variant="primary" :href="blog.permalinkUrl" target="_blank">
                  View in Facebook
                </b-button>
                <b-button class="font-weight-bold" variant="danger" @click="deletePost(blog.id)" target="_blank">
                  Delete
                </b-button>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <!--/ blogs -->

      <!-- sidebar -->
      <div slot="sidebar" class="blog-sidebar py-2 py-lg-0">
        <b-row>
          <b-col class="my-2 d-flex justify-content-center">
            <b-button variant="primary" block :to="{
              path: '/marketing/blog/add',
              query: { access_token: facebookToken },
            }">
              Add Content
            </b-button>
          </b-col>
        </b-row>
      </div>
      <!--/ sidebar -->
    </content-with-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BSpinner,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BButton,
  BDropdown,
  BLink,
  BDropdownItem,
  BAlert,
} from "bootstrap-vue";

import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VFacebookLogin from "vue-facebook-login-component";
import { mapGetters, mapActions } from "vuex";

import axios from "axios";

export default {
  components: {
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    VFacebookLogin,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BSpinner,
    BCardTitle,
    BMedia,
    BAvatar,
    BButton,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    ContentWithSidebar,
    ToastificationContent,
    BAlert,
  },
  data() {
    return {

      beakerToken: localStorage.getItem("accessToken"),
      search_query: "",
      blogList: [],
      blogSidebar: {},
      facebookImage: "https://cdn.pixabay.com/photo/2021/08/16/08/55/facebook-6549798_1280.jpg",
      currentPage: 1,
      perPage: 1,
      rows: 140,
      name: "",
      loaderDelete: false,
      email: "",
      personalID: "",
      loading: false,
      picture: "",
      fb: null,
      message: "",
      pageid: "",
      FB: {},
      model: {},
      scope: {},
      facebookToken: "",
      pagePost: [],
      userId: {},
    };
  },

  computed: {
    ...mapGetters("marketingModule", {
      appid: "appid",
      posts: "posts",
      loadingMarketing: "loading",
    }),
    firstAppId() {
      return this.appid && this.appid.length > 0 ? this.appid[0].appId : null;
    },
  },

  async mounted() {
    this.getAppIdAction();
  },
  methods: {
    ...mapActions("marketingModule", ["getAppIdAction", "getPostAction", "removePostAction"]),
    handleSdkInit({ FB, scope }) {
      this.FB = FB;
      this.scope = scope;
    },

    logout() {
      window.location.reload();
    },

    onLogin(response) {
      console.log(response);
      this.FB.api("/me/accounts", "GET", { fields: "name,access_token" }, (data) => {
        this.facebookToken = data.data[0].access_token;
        localStorage.setItem("facebookToken", this.facebookToken);
        this.getPostAction(this.facebookToken);

      });
      this.FB.api("/me", "GET", { fields: "name" }, (data) => {
        this.userId = data;
      });
    },



    async deletePost(item) {

      let token = this.facebookToken;
      let access_token = this.beakerToken;

      console.log(token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      let postId = item;

      this.$swal({
        title: "Deleting",
        text: "Please wait...",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        content: {
          element: "div",
          attributes: {
            className: "swal-spinner",
            style: "display:flex;justify-content:center;",
          },
          children: `
      <div className='spinner'>
        <div className='double-bounce1'></div>
        <div className='double-bounce2'></div>
      </div>
    `,
        },
      });

      try {
        const response = await axios.delete(`https://graph.facebook.com/${postId}`, {
          params: {
            access_token: token,
          },
        });

        this.$swal.close();
        this.success();
        this.getPostAction(this.facebookToken);

      } catch (error) {

        this.$swal.close();
        this.error(error.response.data)
      }
    },
    success() {
      this.$swal({
        title: 'Post Deleted!',
        text: 'Post has been successfully deleted!',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    error(error) {
      this.$swal({
        title: 'Error!',
        text: ' Error deleting post!' + error,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    showToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Welcome ${this.name}`,
          icon: "BellIcon",
          text: "👋 You have successfully logged in!",
          variant: "Success",
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
</style>
